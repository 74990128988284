<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-12">
        <div class="">
          <div class="card-header card-header-flex">
            <h5 class="my-3"><strong>NOTIFICATIONS</strong></h5>
            <a-tabs defaultActiveKey="1" class="kit-tabs-bold mr-auto">
              <!-- <a-tab-pane key="2"> -->
                <!-- <span slot="tab"> -->
                  <!-- Notifications -->
                  <!-- <span v-if="count !== 0" class="ml-2 badge badge-primary text-uppercase">{{ count }} new</span> -->
                <!-- </span> -->
              <!-- </a-tab-pane> -->
            </a-tabs>
          </div>
          <div class="card-body">
            <div class="kit__utils__table mb-4">
              <a-table :pagination="{ pageSize: 20 }" :loading="loading" :columns="columns" :dataSource="notifications">
                <!-- <i slot="favorites" slot-scope="value"
                  :class="[value === true ? 'icmn-star-full text-warning' : 'icmn-star-full text-default']" /> -->
                <span slot="message" slot-scope="Message" class="notification-message">
                  <p>{{ Message }}</p>
                </span>
                <span slot="date" slot-scope="timeCreated">
                  <p :style="{ marginLeft: '-10px' }">{{ convertTimestampDate(timeCreated) }}</p>
                </span>
                <span slot="time" slot-scope="timeCreated">
                  <p>{{ convertTimestampTime(timeCreated) }}</p>
                </span>
                <i slot="attachments" slot-scope="value" v-if="value === true" class="icmn-attachment text-default" />
                <!-- <a-pagination :total="5" show-less-items /> -->
                <a-pagination :current="current" :total="10" />
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getNotifications } from '@/api/notification'
const columns = [
  {
    title: 'Message',
    dataIndex: 'Message',
    scopedSlots: { customRender: 'message' },
  },
  {
    title: 'Date',
    dataIndex: 'TimeCreated',
    scopedSlots: { customRender: 'date' },
  },
  {
    title: 'Time',
    dataIndex: 'TimeCreated',
    scopedSlots: { customRender: 'time' },
  },
]
export default {
  // components: { vueCustomScrollbar },
  data: function () {
    return {
      columns,
      selectedRowKeys: [],
      notifications: [],
      count: 0,
      loading: false,
    }
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    convertTimestampTime: function (timestamp) {
      const timeStamp = timestamp + '000'
      const date = new Date(parseInt(timeStamp))
      return date.toLocaleTimeString('en-US', {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
      })
    },
    convertTimestampDate: function (timestamp) {
      const timeStamp = timestamp + '000'
      const date = new Date(parseInt(timeStamp))
      return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
    },
  },
  mounted() {
    getNotifications()
      .then((res) => {
        this.loading = true
        this.notifications = res.sort(function (x, y) {
          return y.TimeCreated - x.TimeCreated
        })
        // this.notifications = this.notifications.slice(0, 5)
        this.count = this.notifications.filter((el) => el.Seen === 0).length
        this.loading = false
      })
      .catch((err) => {
        console.log(err)
      })
  },
}
</script>
<style lang="scss" scoped>
/* @import "./style.module.scss"; */
.no-hover {
  pointer-events: none;
  background-color: red;
}
.ant-table-tbody > tr.ant-table-row-level-0:hover > td {
  background: none;
}
</style>
